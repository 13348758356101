import React from 'react';
import PropTypes from 'prop-types';
import TextFieldSetting from '../TextFieldSetting';
import ToggleButtonSetting from '../ToggleButtonSetting';
import ToggleSwitchSetting from '../ToggleSwitchSetting';
import LogoUploadSetting from '../LogoUploadSetting';
import ColorPickerSetting from '../ColorPickerSetting';
import DropdownSetting from '../DropdownSetting';

export default function SettingsInputSwitcher(props) {
  const {
    type,
    setting,
    setValues,
    refreshParent,
    setErrorMessage,
    setSuccessMessage,
    options,
    defaultOption,
    isCreateMode,
    contextType,
    isMultiple,
  } = props;
  switch (type) {
    case 'switch':
      return (
        <ToggleSwitchSetting
          setting={setting}
          refreshParent={refreshParent}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
          defaultOption={defaultOption}
          contextType={contextType}
        />
      );
    case 'text':
      return (
        <TextFieldSetting
          setValues={setValues}
          setting={setting}
          refreshParent={refreshParent}
          isCreateMode={isCreateMode}
          contextType={contextType}
        />
      );
    case 'toggle':
      return (
        <ToggleButtonSetting
          setting={setting}
          refreshParent={refreshParent}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
          options={options}
          defaultOption={defaultOption}
          contextType={contextType}
        />
      );
    case 'dropdown':
      return (
        <DropdownSetting
          setting={setting}
          options={options}
          contextType={contextType}
          refreshParent={refreshParent}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          isMultiple={isMultiple}
        />
      );

    case 'logoUpload':
      return (
        <LogoUploadSetting
          setting={setting}
          setValues={setValues}
          refreshParent={refreshParent}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
          contextType={contextType}
        />
      );
    case 'colorPicker':
      return (
        <ColorPickerSetting
          setting={setting}
          refreshParent={refreshParent}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
          contextType={contextType}
        />
      );
    default:
      return null;
  }
}

SettingsInputSwitcher.propTypes = {
  type: PropTypes.string.isRequired,
  setting: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  contextType: PropTypes.oneOf(['site', 'customer']).isRequired,
  refreshParent: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  setErrorMessage: PropTypes.func,
  setValues: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  defaultOption: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    }),
    PropTypes.bool,
  ]),
  isMultiple: PropTypes.bool,
  isCreateMode: PropTypes.bool,
};

SettingsInputSwitcher.defaultProps = {
  isMultiple: false,
  defaultOption: { value: '' },
  options: [{ name: '' }],
  refreshParent: () => {},
  setSuccessMessage: () => {},
  setErrorMessage: () => {},
  setValues: () => {},
  isCreateMode: false,
};
