import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  TableRow, TableCell, Tooltip, Typography,
} from '@mui/material';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import DeleteOnePiiButton from './DeleteOnePiiButton';
import PushAccuroButton from '../integrations/Accuro/PushAccuroButton';
import CopyReportButton from './CopyReportButton';
import CopyTraceButton from './CopyTraceButton';
import ResendSMSButton from './ResendSMSButton';
import { useApiClient } from '../../context/ApiClientContext';
import { axiosErrorToUserMessage } from '../../services/axios';

function IntakeTableRow({
  row, header,
  activeSiteId, integrationSettings,
  isNarrowScreen, canReviewIntake, canViewTraces,
  setSuccessMessage, setErrorMessage,
  showAppointmentField,
}) {
  const navigate = useNavigate();
  const apiClient = useApiClient();

  const mapIntakeStatus = (status) => {
    // Maps the intake status code from backend to a human-readable format.
    const intakeStatusMap = {
      opened: 'Opened',
      inProgress: 'In Progress',
      idle: 'Partially completed',
      consentDeclined: 'Consent Declined',
      integrationFailed: 'Integration Failed',
      completed: 'Completed',
      created: 'Created',
      abandoned: 'Abandoned',
      expired: 'Expired',
    };

    const commonStyle = {
      borderRadius: '16px',
      padding: '4px 12px',
      fontSize: '0.75rem',
      fontWeight: '500',
      whiteSpace: 'nowrap',
    };

    const statusStylesMap = {
      opened: {
        ...commonStyle, backgroundColor: 'rgba(29, 78, 216, 0.2)', color: 'rgb(15 51 154)',
      },
      inProgress: {
        ...commonStyle, backgroundColor: 'rgba(180, 83, 9, 0.2)', color: '#b45309',
      },
      idle: {
        ...commonStyle, backgroundColor: 'rgba(34, 197, 94, 0.1)', color: '#21954C',
      },
      consentDeclined: {
        ...commonStyle, backgroundColor: '#6f6f6f54', color: '#28282899',
      },
      integrationFailed: {
        ...commonStyle, backgroundColor: 'rgba(220, 38, 38, 0.1)', color: 'rgb(199 11 11)',
      },
      completed: {
        ...commonStyle, backgroundColor: 'rgba(34, 197, 94, 0.2)', color: '#15803D',
      },
      created: {
        ...commonStyle, backgroundColor: 'rgba(234, 179, 8, 0.2)', color: '#A16207',
      },
      abandoned: {
        ...commonStyle, backgroundColor: 'rgba(87, 83, 78, 0.2)', color: '#57534E',
      },
      expired: {
        ...commonStyle, backgroundColor: 'rgba(87, 83, 78, 0.2)', color: '#57534E',
      },
    };

    return {
      readableStatus: intakeStatusMap[status] ?? status,
      style: statusStylesMap[status] ?? { ...commonStyle, backgroundColor: '#6f6f6f54', color: '#28282899' },
    };
  };

  const handleReviewIntake = async () => {
    try {
      if (integrationSettings.integrationType === 'silverberry') {
        const params = { iframeIntegrationType: 'silverberry', siteId: activeSiteId, patientId: row.patientId };
        const response = await apiClient.getIframePageUrl(params);
        const url = response?.data;
        if (!url) {
          throw new Error('URL not returned');
        }
        navigate('/silverberryReview', { state: { url } });
      } else {
        navigate(`/review/${row.mkey}`);
      }
    } catch (error) {
      setErrorMessage(axiosErrorToUserMessage(error) || 'Error reviewing intake');
    }
  };

  const clickableStyle = {
    cursor: 'pointer',
    color: '#0056b3',
    fontWeight: 'bold',
  };

  const isStatusClickable = (status) => {
    if (integrationSettings.integrationType === 'silverberry') {
      return status === 'completed';
    }
    return ['inProgress', 'idle', 'completed', 'integrationFailed', 'abandoned'].includes(status);
  };

  // Uniquely formats the content of each cell based on the column ID and value.
  const formatCell = (id, value) => {
    switch (id) {
      case 'patientId':
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isStatusClickable(row.intakeStatus) && canReviewIntake ? (
              <Tooltip title="Review Intake">
                <span
                  style={clickableStyle}
                  onClick={handleReviewIntake}
                  role="button"
                  tabIndex={0}
                  data-testid="patientId"
                  onKeyDown={handleReviewIntake}
                >
                  {value ?? 'Not available'}
                </span>
              </Tooltip>
            ) : (
              <Typography variant="semibold" data-testid="patientId">
                {value ?? 'Not available'}
              </Typography>
            )}
            {row.patientIdNonUnique && (
              <Tooltip title="There is another intake with the same Patient ID. Please ensure you have the right patient.">
                <WarningAmberRoundedIcon color="warning" style={{ marginLeft: '8px' }} />
              </Tooltip>
            )}
          </div>
        );
      case 'createdAt':
        return moment(value).format('MMM DD YYYY, h:mm:ss a');
      case 'intakeStatus': {
        if (integrationSettings.accuroEnabled === 'true' && value === 'integrationFailed') {
          return 'Accuro Integration failed';
        }
        const { readableStatus, style } = mapIntakeStatus(value);

        return (
          <span style={style} data-testid="intakeStatus">
            {readableStatus}
          </span>
        );
      }
      case 'appointmentDate':
        return showAppointmentField && value ? moment(value).format('MMM DD, h:mmA') : null;
      default:
        return value ?? 'Not available';
    }
  };

  const renderOptions = (intakeRow) => (
    <>
      {
        (row.intakeStatus === 'integrationFailed' && integrationSettings.accuroEnabled === 'true') && (
          <PushAccuroButton
            mkey={intakeRow.mkey}
            patientId={intakeRow.patientId}
            accuroSettings={integrationSettings}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        )
      }
      {canReviewIntake && (
        <>
          {!isNarrowScreen
          && <CopyReportButton mkey={intakeRow.mkey} activeSiteId={activeSiteId} />}
          {canViewTraces && !isNarrowScreen
          && <CopyTraceButton mkey={intakeRow.mkey} activeSiteId={activeSiteId} />}
          <DeleteOnePiiButton
            mkey={intakeRow.mkey}
            patientId={intakeRow.patientId}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
            data-testid="trashCanIcon"
          />
        </>
      )}
      {(row.intakeStatus === 'created' || row.intakeStatus === 'inProgress') && (row.phoneNumber) && (
        <ResendSMSButton
          mkey={intakeRow.mkey}
          siteId={activeSiteId}
          phoneNumber={row.phoneNumber}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
    </>
  );

  return (
    <TableRow>
      {header.map((column) => (
        <TableCell key={column.id}>
          {column.id === 'options' ? renderOptions(row) : formatCell(column.id, row[column.id])}
        </TableCell>
      ))}
    </TableRow>
  );
}

IntakeTableRow.propTypes = {
  row: PropTypes.shape({
    hxId: PropTypes.string.isRequired,
    mkey: PropTypes.string.isRequired,
    intakeStatus: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    patientId: PropTypes.string,
    patientIdNonUnique: PropTypes.bool,
    appointmentDate: PropTypes.string,
    phoneNumber: PropTypes.string,
  }).isRequired,
  header: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  activeSiteId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  integrationSettings: PropTypes.object.isRequired,
  isNarrowScreen: PropTypes.bool.isRequired,
  canReviewIntake: PropTypes.bool.isRequired,
  canViewTraces: PropTypes.bool.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  showAppointmentField: PropTypes.bool.isRequired,
};

export default IntakeTableRow;
